import { FormikErrors } from 'formik'

import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { GuestBoxesFormValues } from '../../GuestBoxesForm'

import styles from './GuestBoxCard.module.scss'
import { QuantityInput } from 'components/QuantityInput'
import AssetNumber from 'components/AssetNumber/AssetNumber'
import { RemoveBoxRowButton } from 'forms/BoxesForm/components/RemoveBoxRowButton'

export type GuestBoxCardProps = {
  errors?: FormikErrors<GuestBoxesFormValues>
  index: number
  onRemove?: (index: number) => void
}

const valueKeyPrefix = 'boxes'
const fieldIdPrefix = 'box'

export const GuestBoxCard = ({ errors, index, onRemove }: GuestBoxCardProps): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.BoxRow}>
      <div className={styles.Data}>
        <div className={styles.FormGroup}>
          <div className={styles.TitleRow}>
            <div className={styles.TitleLeft}>
              <AssetNumber number={index + 1} />
              <label htmlFor={`${fieldIdPrefix}-dimensions-${index}`}>Box Dimensions</label>
            </div>
            {onRemove && (
              <div className={styles.RemoveButtonWrapper}>
                <RemoveBoxRowButton index={index} onClick={() => onRemove(index)} />
              </div>
            )}
          </div>
          <div className={styles.Dimensions}>
            <div className={styles.QuantityRow}>
              <label htmlFor={`${valueKeyPrefix}[${index}].dimensions.length`}>
                Length (Inches)
              </label>
              <QuantityInput
                id={`${valueKeyPrefix}[${index}].dimensions.length`}
                name={`${valueKeyPrefix}[${index}].dimensions.length`}
              />
            </div>
            <div className={styles.QuantityRow}>
              <label htmlFor={`${valueKeyPrefix}[${index}].dimensions.width`}>Width (Inches)</label>
              <QuantityInput
                id={`${valueKeyPrefix}[${index}].dimensions.width`}
                name={`${valueKeyPrefix}[${index}].dimensions.width`}
              />
            </div>
            <div className={styles.QuantityRow}>
              <label htmlFor={`${valueKeyPrefix}[${index}].dimensions.height`}>
                Height (Inches)
              </label>
              <QuantityInput
                id={`${valueKeyPrefix}[${index}].dimensions.height`}
                name={`${valueKeyPrefix}[${index}].dimensions.height`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    {errors?.[valueKeyPrefix]?.[index] && (
      <div className={styles.Errors}>
        <BootstrapFormikFeedback name={`${valueKeyPrefix}[${index}].dimensions.length`} />
        <BootstrapFormikFeedback name={`${valueKeyPrefix}[${index}].dimensions.width`} />
        <BootstrapFormikFeedback name={`${valueKeyPrefix}[${index}].dimensions.height`} />
      </div>
    )}
  </div>
)
