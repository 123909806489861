import { FieldArray, Formik } from 'formik'
import React from 'react'

import { Form } from 'components/Form'
import { FormContainer, TPreviousPageHandler } from 'components/FormContainer'
import { GuestPallet, SelectOption, TFormSubmit } from 'types'

import { AddRowButton } from 'components/AddRowButton'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useModalState } from 'hooks/useModalState'
import { GuestPalletCard } from './components/GuestPalletCard/GuestPalletCard'
import { GuestPalletsSchema } from './schema'
export interface FormValues {
  pallets: GuestPallet[]
}

export type Props = {
  assetTypeOptions: SelectOption[]
  initialValues: FormValues
  onGoToPreviousPage: TPreviousPageHandler
  onSubmit: TFormSubmit<FormValues, void>
  title: string
}

export const emptyGuestPallet: GuestPallet = {
  asset_id: 0,
  dimensions: {
    height: 0,
    length: 0,
    width: 0,
  },
  quantity: 0,
  weight: 0,
}

export const GuestPalletsForm = ({
  initialValues,
  onGoToPreviousPage,
  onSubmit,
  assetTypeOptions,
  title,
}: Props): JSX.Element => {
  const {
    hide: hideConfirmDeleteModal,
    open: openConfirmDeleteModal,
    state: confirmDeleteModalState,
  } = useModalState<{ indexToDelete: number }>({ indexToDelete: 0 })

  const onRemovePalletRow = React.useCallback(
    (index: number) => {
      openConfirmDeleteModal({ indexToDelete: index })
    },
    [openConfirmDeleteModal],
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={GuestPalletsSchema}
    >
      {({ errors, handleSubmit, isSubmitting, submitCount, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormContainer
            formError={
              submitCount > 0 && errors.pallets && typeof errors.pallets === 'string'
                ? errors.pallets
                : ''
            }
            fullWidth
            onGoToPreviousPage={onGoToPreviousPage}
            isSubmitting={isSubmitting}
            title={title}
            isVersion2
          >
            <FieldArray name="pallets">
              {({ push, remove }) => (
                <>
                  {values.pallets.map((pallet, index) => (
                    <GuestPalletCard
                      errors={submitCount > 0 ? errors : undefined}
                      index={index}
                      key={`${pallet.asset_id}_${index}`}
                      onRemove={values.pallets.length > 1 ? onRemovePalletRow : undefined}
                      assetTypeOptions={assetTypeOptions}
                    />
                  ))}

                  <div className="d-flex justify-content-end">
                    <AddRowButton
                      label="Add Another Pallet"
                      onClick={() => push({ ...emptyGuestPallet })}
                    />
                  </div>

                  <ConfirmationDialog
                    acceptText="Delete"
                    cancelText="Cancel"
                    disabled={false}
                    isDelete
                    onAccept={() => {
                      remove(confirmDeleteModalState.indexToDelete)
                      hideConfirmDeleteModal()
                    }}
                    onCancel={hideConfirmDeleteModal}
                    show={confirmDeleteModalState.show}
                    title="Remove Pallet"
                  >
                    Are you sure you want to delete this item? This action cannot be undone.
                  </ConfirmationDialog>
                </>
              )}
            </FieldArray>
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
