import { api } from 'lib/api/base'
import { GUEST_ENDPOINTS } from 'lib/api/endpoints'
import { GuestAssetsResponse } from 'lib/api/types'
import { GuestBoxesPayload } from 'pages/OrderPages/pages/GuestBoxesPage/helpers'

export const submitBoxesPage = async (payload: GuestBoxesPayload) => {
  const { boxes, token, user_action } = payload
  const formData = new FormData()
  formData.append('token', token)
  formData.append('boxes', JSON.stringify(boxes))
  formData.append('user_action', user_action)

  const { data } = await api.post<GuestAssetsResponse>(GUEST_ENDPOINTS.guestBoxes, formData)

  return data
}
