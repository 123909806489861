import { JSX } from 'react'

type Props = {
  isVersion2?: boolean
}

export const MinusIcon = ({ isVersion2 }: Props): JSX.Element => {
  if (isVersion2) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="evenodd">
          <path d="M1.432 10a8.57 8.57 0 0 0 8.564 8.568 8.567 8.567 0 0 0 8.572-8.558 8.566 8.566 0 0 0-8.574-8.577A8.567 8.567 0 0 0 1.432 10m8.566 10C4.502 19.998.002 15.498 0 10.003-.002 4.499 4.513-.012 10.012 0 15.515.012 20.004 4.51 20 10.007c-.004 5.495-4.507 9.995-10.002 9.993" />
          <path d="M6.535 10.999c-.396 0-.645-.363-.487-.704a.48.48 0 0 1 .443-.291c.142-.007.285-.002.428-.002h6.536c.367 0 .602.27.533.608-.049.239-.252.39-.533.39H9.988z" />
        </g>
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g fill="#03a9d0" fillRule="evenodd">
          <path d="M1.564 10.997c.011 5.192 4.144 9.41 9.394 9.43 5.121.019 9.4-4.087 9.425-9.374.025-5.099-4.036-9.416-9.378-9.439-5.17-.022-9.39 4.093-9.441 9.383M10.972.043C17.01.03 21.946 4.963 21.957 11.02c.01 6.026-4.91 10.952-10.966 10.98C4.967 22.027.009 17.079.001 11.03-.01 4.982 4.91.055 10.971.042" />
          <path d="M5.894 12c-.24 0-.47-.023-.656-.269-.212-.28-.286-.61-.208-.989.083-.398.276-.65.59-.713.087-.017.176-.028.264-.028h10.24c.443 0 .695.196.822.639.182.628-.115 1.274-.619 1.344-.087.012-.176.015-.264.015L11.005 12H5.894z" />
        </g>
      </svg>
    )
  }
}
