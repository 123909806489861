import classNames from 'classnames'
import { FormikErrors } from 'formik'

import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { QuantityInput } from 'components/QuantityInput'
import { SelectField } from 'components/SelectField'
import { FormValues } from 'forms/PalletsForm'
import { SelectOption } from 'types'

import AssetNumber from 'components/AssetNumber/AssetNumber'
import { RemoveRowButton } from 'forms/PalletsForm/components/PalletRow/components/RemoveRowButton'
import styles from './GuestPalletCard.module.scss'

export type Props = {
  assetTypeOptions: SelectOption[]
  errors?: FormikErrors<FormValues>
  index: number
  onRemove?: (index: number) => void
}

export const GuestPalletCard = ({
  errors,
  index,
  onRemove,
  assetTypeOptions,
}: Props): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.TitleRow}>
      <div className={styles.TitleLeft}>
        <AssetNumber number={index + 1} />
        <label htmlFor={`$pallet-${index}`}>Pallet</label>
      </div>
      {onRemove && (
        <div className={styles.RemoveButtonWrapper}>
          {onRemove && (
            <RemoveRowButton
              index={index}
              onClick={() => onRemove(index)}
              className={styles.DeleteButton}
            />
          )}
        </div>
      )}
    </div>

    <div className={styles.Divider} />

    <div className={styles.PalletInfo}>
      <div className={styles.PalletInfoSection}>
        <div className={classNames(styles.FormGroup, styles.AssetType)}>
          <label htmlFor={`pallet-asset-${index}`}>Asset Type</label>
          <SelectField
            className={styles.AssetTypeSelect}
            id={`pallet-asset-${index}`}
            name={`pallets[${index}].asset_id`}
            options={assetTypeOptions}
          />
        </div>
        <div className={styles.FormGroup}>
          <label htmlFor={`pallet-quantity-${index}`}>Number of Pallets</label>
          <QuantityInput
            isVersion2
            id={`pallet-quantity-${index}`}
            name={`pallets[${index}].quantity`}
            aria-label="quantity"
          />
        </div>
      </div>

      <div className={styles.DividerLabel}>
        <div className={styles.Divider} />
        <div className={styles.DividerLabelText}>Pallet Info</div>
        <div className={styles.Divider} />
      </div>

      <div className={styles.PalletInfoSection}>
        <div className={styles.FormGroup}>
          <label htmlFor={`length-${index}`}>Length (Inches)</label>
          <QuantityInput
            isVersion2
            id={`length-${index}`}
            name={`pallets[${index}].dimensions.length`}
            aria-label="length"
          />
        </div>

        <div className={styles.FormGroup}>
          <label htmlFor={`width-${index}`}>Width (Inches)</label>

          <QuantityInput
            isVersion2
            id={`width-${index}`}
            name={`pallets[${index}].dimensions.width`}
            aria-label="width"
          />
        </div>

        <div className={styles.FormGroup}>
          <label htmlFor={`width-${index}`}>Height (Inches)</label>

          <QuantityInput
            isVersion2
            id={`height-${index}`}
            name={`pallets[${index}].dimensions.height`}
            aria-label="height"
          />
        </div>

        <div className={styles.FormGroup}>
          <label htmlFor={`pallet-weight-${index}`}>Pallet Weight (lbs - Optional)</label>

          <QuantityInput
            isVersion2
            id={`pallet-weight-${index}`}
            name={`pallets[${index}].weight`}
            aria-label="weight"
          />
        </div>
      </div>
    </div>

    {errors && (
      <div className={styles.Errors}>
        <BootstrapFormikFeedback name={`pallets[${index}].asset_id`} />
        <BootstrapFormikFeedback name={`pallets[${index}].quantity`} />
        <BootstrapFormikFeedback name={`pallets[${index}].dimensions.length`} />
        <BootstrapFormikFeedback name={`pallets[${index}].dimensions.width`} />
        <BootstrapFormikFeedback name={`pallets[${index}].dimensions.height`} />
        <BootstrapFormikFeedback name={`pallets[${index}].weight`} />
      </div>
    )}
  </div>
)
