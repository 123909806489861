import { WizardPage } from 'components/WizardPage/WizardPage'
import { emptyGuestPallet, GuestPalletsForm } from 'forms/GuestPalletsForm/GuestPalletsForm'
import { useGuestOrderContext } from 'hooks/useGuestOrderContext'
import { JSX } from 'react'
import { GuestAssetType } from 'types'

const GuestPalletsPage = (): JSX.Element => {
  const guestOrderContext = useGuestOrderContext()
  const assetTypes: GuestAssetType[] =
    guestOrderContext.guestOrder?.inventory_parameters?.asset_types || []

  const palletTypeOptions = assetTypes.map(assetType => ({
    label: assetType.name,
    value: assetType.asset_id,
  }))

  const initialPallets = [{ ...emptyGuestPallet }]

  return (
    <WizardPage>
      <GuestPalletsForm
        initialValues={{ pallets: initialPallets }}
        onGoToPreviousPage={() => {}}
        onSubmit={() => {}}
        assetTypeOptions={palletTypeOptions}
        title="Enter details for your pallets"
      />
    </WizardPage>
  )
}

export default GuestPalletsPage
