import { GuestBoxesFormValues } from 'forms/GuestBoxesForm/GuestBoxesForm'
import { History } from 'history'
import { submitBoxesPage } from 'lib/api/guest/submitBoxesPage'
import { GuestBox, GuestUserAction } from 'types'

export type GuestBoxesPayload = {
  boxes: GuestBox[]
  token: string
  user_action: GuestUserAction
}

const handleSubmit = async (formValues: GuestBoxesFormValues, token: string, history: History) => {
  const payload: GuestBoxesPayload = {
    boxes: formValues.boxes,
    token,
    user_action: GuestUserAction.next,
  }

  const res = await submitBoxesPage(payload)
  history.push(res.page_path)
}

export { handleSubmit }
