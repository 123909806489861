import { TrashIcon } from 'components/TrashIcon'

import styles from './RemoveRowButton.module.scss'
import classNames from 'classnames'

export type Props = {
  className?: string
  index: number
  onClick: () => void
}

export const RemoveRowButton = ({ index, onClick, className }: Props): JSX.Element => (
  <button
    aria-label={`Remove pallet ${index + 1}`}
    className={classNames(styles.Button, className)}
    onClick={onClick}
    title="Remove pallet"
    type="button"
  >
    <TrashIcon />
  </button>
)
