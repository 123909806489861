import React from 'react'
import Col from 'react-bootstrap/Col'
import { FieldArray, Formik } from 'formik'
import Row from 'react-bootstrap/Row'
import * as yup from 'yup'

import { useModalState } from 'hooks/useModalState'
import { Form } from 'components/Form'
import { FormContainer, TPreviousPageHandler } from 'components/FormContainer'
import { GuestBoxCard } from './components/GuestBoxCard/GuestBoxCard'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { GuestBox, TFormSubmit } from 'types'
import { AddRowButton } from 'components/AddRowButton'

export type GuestBoxesFormValues = {
  boxes: GuestBox[]
}

export type GuestBoxesFormProps = {
  initialValues: GuestBoxesFormValues
  onGoToPreviousPage: TPreviousPageHandler
  onSubmit: TFormSubmit<GuestBoxesFormValues, void>
  title: string
}

export const emptyBox: GuestBox = {
  dimensions: {
    height: 0,
    length: 0,
    width: 0,
  },
  quantity: 1,
}

export const GuestBoxesForm = ({
  initialValues,
  onSubmit,
  onGoToPreviousPage,
  title,
}: GuestBoxesFormProps): JSX.Element => {
  const {
    hide: hideConfirmDeleteModal,
    open: openConfirmDeleteModal,
    state: confirmDeleteModalState,
  } = useModalState<{ indexToDelete: number }>({ indexToDelete: 0 })

  const onRemoveRow = React.useCallback(
    (index: number) => {
      openConfirmDeleteModal({ indexToDelete: index })
    },
    [openConfirmDeleteModal],
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={GuestBoxesSchema}
    >
      {({ errors, handleSubmit, isSubmitting, submitCount, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormContainer
            formError={
              submitCount > 0 && errors.boxes && typeof errors.boxes === 'string'
                ? errors.boxes
                : ''
            }
            fullWidth
            onGoToPreviousPage={onGoToPreviousPage}
            isSubmitting={isSubmitting}
            isVersion2
            title={title}
          >
            <FieldArray name="boxes">
              {({ push, remove }) => (
                <>
                  {values.boxes.map((_box, index) => {
                    return (
                      <GuestBoxCard
                        errors={submitCount > 0 ? errors : undefined}
                        index={index}
                        key={`box_${index}`}
                        onRemove={values.boxes.length > 1 ? onRemoveRow : undefined}
                      />
                    )
                  })}

                  <Row className="justify-content-end">
                    <Col className="text-right" md={3} xs={12}>
                      <AddRowButton label="Add Box" onClick={() => push({ ...emptyBox })} />
                    </Col>
                  </Row>

                  <ConfirmationDialog
                    acceptText="Yes"
                    cancelText="No"
                    disabled={false}
                    onAccept={() => {
                      remove(confirmDeleteModalState.indexToDelete)
                      hideConfirmDeleteModal()
                    }}
                    onCancel={hideConfirmDeleteModal}
                    show={confirmDeleteModalState.show}
                    title="Remove Box"
                  >
                    Are you sure you want to remove the box?
                  </ConfirmationDialog>
                </>
              )}
            </FieldArray>
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}

const GuestBoxesSchema = yup.object<GuestBoxesFormValues>().shape({
  boxes: yup
    .array()
    .of(
      yup.object().shape({
        dimensions: yup.object().shape({
          height: yup
            .number()
            .required()
            .min(1, 'Height should be greater than 0.')
            .max(92, 'Height should not be greater than 92.'),
          length: yup
            .number()
            .required()
            .min(1, 'Length should be greater than 0.')
            .max(96, 'Length should not be greater than 96.'),
          width: yup
            .number()
            .required()
            .min(1, 'Width should be greater than 0.')
            .max(96, 'Width should not be greater than 96.'),
        }),
      }),
    )
    .test('at-least-one-box', 'At least one box must be completed.', boxes => {
      if (!boxes || boxes.length < 1) {
        return false
      } else {
        return true
      }
    }),
})
