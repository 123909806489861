import { WizardPage } from 'components/WizardPage/WizardPage'
import { GuestBoxesForm, emptyBox } from 'forms/GuestBoxesForm/GuestBoxesForm'
import { useGuestOrderContext } from 'hooks/useGuestOrderContext'
import { JSX } from 'react'
import { useHistory } from 'react-router-dom'
import { GuestBox } from 'types'
import * as helpers from './helpers'

const GuestBoxesPage = (): JSX.Element => {
  const guestOrderContext = useGuestOrderContext()
  const token = guestOrderContext.guestOrder?.token || ''
  const history = useHistory()
  const initialRows: GuestBox[] = [{ ...emptyBox }]

  return (
    <WizardPage>
      <GuestBoxesForm
        initialValues={{ boxes: initialRows }}
        onGoToPreviousPage={() => null}
        onSubmit={values => helpers.handleSubmit(values, token, history)}
        title="Enter details for your boxes"
      />
    </WizardPage>
  )
}

export default GuestBoxesPage
